/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/camelcase */

export enum ChatContextTypeDto {
  COURSE_SECTION_ID = 'COURSE_SECTION_ID',
  EVAL = 'EVAL',
  ASSIGNMENT_ID = 'ASSIGNMENT_ID'
}

export enum ChatModelNameDto {
  gpt4 = 'gpt4',
  gpt35 = 'gpt35',
  titan = 'titan', // AWS Titan TG1 Large
  claude = 'claude', // Claude 2
}

export enum ChatTypeDto {
  FLORENCE_USER_CHAT = 'FLORENCE_USER_CHAT',
  BATCH_EVAL_CHAT = 'BATCH_EVAL_CHAT',
}

export enum ChatAttributes {
  isTestUser = 'isTestUser',
  isMasqueradeUser = 'isMasqueradeUser',
  isDirectAccessByFeatureFlag = 'isDirectAccessByFeatureFlag',
  isDirectAccessByIsbn = 'isDirectAccessByIsbn',
  institutionNormalized = 'institutionNormalized',
  emailDomain = 'emailDomain',
  userRole = 'userRole',
}

export type ChatDto = {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  userId: number;
  contextId: string;
  contextType: ChatContextTypeDto;
  status: ChatStatusDto;
  entries: ChatEntryDto[];
  type: ChatTypeDto;
  modelName: ChatModelNameDto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<ChatAttributes, any>;
}

export enum ChatStatusDto {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum ChatEntryAuthorDto {
  USER = 'USER',
  BOT = 'BOT'
}

export enum ChatEntryTypeDto {
  EXPLAINER = 'EXPLAINER',
  MESSAGE = 'MESSAGE',
  SHELL = 'SHELL'
}

export enum ChatQueryIntentDto {
  COMMENT = 'COMMENT',
  QUESTION = 'QUESTION',
  TEST = 'TEST',
}

export enum SearchParam {
  RESOURCE_TYPE = 'resource_type', // ChatResourceTypeDto
  REFERENCE_CLASS = 'reference_class', // ChatReferenceClassDto
  RESOURCE_URI = 'resource_uri', // string
  RESOURCE_NAME = 'resource_name', // string
  LOCATIONS = 'locations', // list[Location] = None
  CONTENT_TEXT = 'content_text', // string
  ISBN = 'isbn', // string
}

type SearchFilter = Partial<{
  [key in SearchParam]: string | SearchFilter | SearchFilter[] | undefined;
}> & {
  AND?: SearchFilter[];
  OR?: SearchFilter[];
};

export type ChatEntryTraceDto = {
  inputTokenCount: number;
  outputTokenCount: number;
  inputText: string;
  outputText: string;
  responseTime: number;
  streamingTime: number;
  version: string;
}

export enum ChatEntryTraceKey {
  STUDENT_STUDY_APP = 'STUDENT_STUDY_APP',
  CONTEXT_RESOLUTION = 'CONTEXT_RESOLUTION',
  SEARCH = 'SEARCH',
  PRIMARY_RESPONSE = 'PRIMARY_RESPONSE',
  CLASSIFICATION_IS_RELEVANT = 'CLASSIFICATION_IS_RELEVANT'
}

export enum OverridePromptType {
  classificationIsRelevant = 'classificationIsRelevant',
  classificationStudyContentGeneration = 'classificationStudyContentGeneration',
  contextResolution = 'contextResolution',
  primaryResponse = 'primaryResponse',
  studyContentGenerationResponse = 'studyContentGenerationResponse',
  declineResponseNoSearchResults = 'declineResponseNoSearchResults',
  postProcess = 'postProcess',
  declineResponse = 'declineResponse',
  rankAndFilterSearchResults = 'rankAndFilterSearchResults'
}

export type PromptOverrideConfigDto = {
  prompt: string;
  userChatItemTemplate: string;
  botChatItemTemplate: string;
  promptBookChunkItemTemplate: string;
  promptVideoItemTemplate: string;
}

export type DehydratedChatEntryDto = {
  message: string;
  searchFilter: SearchFilter;
  traces: Partial<Record<ChatEntryTraceKey, ChatEntryTraceDto>>;
  promptOverride?: Partial<Record<OverridePromptType, PromptOverrideConfigDto>>;
}

export type ChatEntryDto = DehydratedChatEntryDto & {
  id: number; // db id
  chatId: number;
  index: number; // order in chat
  author: ChatEntryAuthorDto; // bot or user
  entryType: ChatEntryTypeDto; // message or maybe something like quiz in the future
  references: ChatReferenceDto[];
  queryIntent: ChatQueryIntentDto;
  errorMessage?: string;
  totalInputTokenCount?: number;
  totalOutputTokenCount?: number;
  createdAt?: string;
  updatedAt?: string;
  _isPostProcessing?: boolean;
}

export type ChatEntryEvaluationDto = {
  id: number; // db id
  userId: string;
  userType: EvaluationUserTypeDto;
  entryId: number;
  comment: string;
  overall: number;
  comprehension: number;
  correctness: number;
  completeness: number;
  harmfulness: number;
  accuracy: string;
  coherency: string;
  organization: string;
  relevancy: string;
  detail: string;
  entryFeedbackReferences: Partial<ChatReferenceEvaluationDto>[];
}

export type ChatReferenceEvaluationDto = {
  id: number; // db id
  chatEntryEvalId: number; // db id of ChatEntryEvaluationDto
  referenceId: number;
  overall: number;
}

export enum EvaluationUserTypeDto {
  CLIENT = 'CLIENT',
  EXPERT = 'EXPERT',
}

export enum ChatReferenceClassDto {
  CITATION = 'CITATION',
  RECOMMENDATION = 'RECOMMENDATION'
}

type ChatReferencePartDto = {
  uri: string;
  index: number;
  type: ChatReferenceTypeDto;
}

export type ChatReferenceContentPropsDto = {
  page: {
    end_page: {
      number: string;
      tag_id: string;
    };
    start_page: {
      number: string;
      tag_id: string;
    };
  };
  source_id: string;
  bread_crumbs: {
    id: string;
    type: 'CHAPTER' | 'SECTION' | 'SUBSECTION';
    title: string;
  }[];
  chapter_number: string;
  source_id_type: 'EEO-ISBN';
}

export type ChatReferenceDto = {
  id: number; // database id
  entryId: number;
  type: ChatReferenceTypeDto;
  referenceClass: ChatReferenceClassDto;
  title: string;
  content: string;
  parts?: ChatReferencePartDto[]; // this is a proposal so do not use this yet
  uri: string; // chapter/15/section/4/
  contentId: string;
  index: number;
  isVisible: boolean;
  confidenceScore: number;
  resourceType: ChatResourceTypeDto;
  resourceUri: string; // book/name_of_book/
  resourceId: string;
  resourceTitle: string;
  contentProps: string; // JSON string
}

export type ChatReferenceWithParsedContentProps = ChatReferenceDto & {
  _contentProps: ChatReferenceContentPropsDto;
};

export enum ChatReferenceTypeDto {
  TEXT = 'TEXT',
}

export enum ChatResourceTypeDto {
  EBOOK = 'EBOOK',
  CASE_STUDY = 'CASE_STUDY',
  LESSON = 'LESSON',
  VIDEO = 'VIDEO',
}

export type ChatPaginationResponseDto = {
  currentPage: number;
  last: boolean;
  totalItems: number;
  totalPages: number;
}

export type ChatPerformanceDto = {
  studentCount: number;
  queryCount: number;
  perDayMetrics: {
    startDate: string;
    endDate: string;
    studentCount: number;
    queryCount: number;
  }[];
}

export enum RemediationActivityTypeDto {
  REVIEW = 'REVIEW',
  APPLY = 'APPLY',
  ASSESS = 'ASSESS',
}

export enum RemediationContentTypeDto {
  EBOOK_READING = 'EBOOK_READING',
  OSMOSIS_VIDEO = 'OSMOSIS_VIDEO',
  HESI_CASE_STUDY = 'HESI_CASE_STUDY',
  SHERPATH_LESSON = 'SHERPATH_LESSON',
  RAAS_QUIZ = 'RAAS_QUIZ',
}

export type RecommendationScoreRange = {
  minScore: number;
  maxScore: number;
  numOfItems: number;
}

export enum RemediationAssignmentSettingsTypeDto {
  HESI_EXAM_SETTINGS = 'HESI_EXAM_SETTINGS',
  UNSTRUCTURED = 'UNSTRUCTURED',
}

export type HesiExamRemediationSettingsDto = {
  rootTaxonomyId: string;
  taxonomyNodeLevel: number;
  activityTypes: {
    activityType: RemediationActivityTypeDto;
    isActive: boolean;
    recommendationMultiplier: number;
    recommendationAdder: number;
    recommendationScoreRanges: RecommendationScoreRange[];
    contentSettings: {
      contentType: RemediationContentTypeDto;
      isActive: boolean;
      minScoreToPass: number;
      recommendationItemScoreRanges: RecommendationScoreRange[];
    }[];
  }[];
}

export type RemediationAssignmentSettingsDto = {
  type: RemediationAssignmentSettingsTypeDto.HESI_EXAM_SETTINGS;
} & HesiExamRemediationSettingsDto | {
  type: RemediationAssignmentSettingsTypeDto.UNSTRUCTURED;
} & Record<string, any>;

export type StudentThemesStatusDto = {
  request: {
    startDate: string;
    numOfDays: number;
    courseSectionIds: number[];
  };
  requestKey: string;
  status: StudentThemesStatus;
  response: StudentThemesResponseDto;
};

export type StudentThemesResponseDto = {
  chatThemes: {
    title: string;
    summary: string;
    sampleMessages: string[];
  }[];
}

export enum StudentThemesStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED= 'COMPLETED',
  ERROR = 'ERROR',
  NOT_STARTED= 'NOT_STARTED'
}

export type RemediationAssignmentDto = {
  id: number;
  assignmentId: number;
  createdByUser: number;
  updatedByUser: number;
  courseSectionId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: RemediationAssignmentSettingsDto;
  createdAt: string;
  updatedAt: string;
}

export enum RemRecContentTypeDto {
  OSMOSIS_VIDEO = 'OSMOSIS_VIDEO',
  EBOOK_CHUNK = 'EBOOK_CHUNK',
  HESI_CASE_STUDY = 'HESI_CASE_STUDY',
  NGN_CASE_STUDY = 'NGN_CASE_STUDY',
  EAQ = 'EAQ',
  SHERPATH_LESSON = 'SHERPATH_LESSON',
}

export type RecommendationDto = {
  id: number;
  assessmentId: number;
  userId: number;
  courseSectionId: number;
  contentType: RemRecContentTypeDto;
  contentId: string;
  status: string;
  recommendationData: {
    title: string;
    subtitle: string;
    timeEstimateSeconds: number;
    topics: string[];
    skipReason: string;
  };
  remediationAssignment: RemediationAssignmentDto;
  createdAt: string;
  updatedAt: string;
  taxonId: string;
}

export enum RemRecItemTypeDto {
  EAQ_QUESTION = 'EAQ_QUESTION'
}

export type RecommendationItemDto = {
  id: number;
  itemType: RemRecItemTypeDto;
  itemId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemData: Record<string, any>;
  remediationRecommendation: RecommendationDto;
  createdAt: string;
  updatedAt: string;
}

export type VectorSearchResponseDto = {
  query: string;
  results: {
    _source: {
      bread_crumbs: {
        id: string;
        type: string;
        title: string;
      }[];
      content_item_id: string;
      document_title: string;
      eid: string;
      source_id: string;
      source_id_type: string;
      title: string;
      content_type: string;
      content_id: string;
      chunk_text: string;
      chunk_id: string;
      content_props: {
        chapter_number: string;
        page: {
          end_page: any;
          start_page: any;
        };
      };
    };
    _score: string;
  }[];
  success: boolean;
  number_of_hits: number;
  total_time: number;
  service_version: string;
  model_name: string;
}

export type LlmResponseDto = {
  inputText: string;
  outputText: string;
  inputTokenCount: number;
  outputTokenCount: number;
  totalTime: string;
  version: string;
  errorMessage: string;
}

export enum LlmModels {
  claude_3_sonnet = 'claude_3_sonnet',
  claude_3_haiku = 'claude_3_haiku',
}

export type VectorSearchDto = {
  queryText: string;
  filter: Record<string, any>;
  numberOfResultsToProvide: number;
  numberOfResultsToTry: number;
  minimumChunkLength: number;
}

export type LlmPromptDto = {
  prompt: string;
  model_id: LlmModels;
  temperature: number;
}

export enum ChatEntitlementTypeDto {
  BASE_SET = 'BASE_SET',
  USER_ENTITLED = 'USER_ENTITLED',
}

export type ChatEntitlementDto = {
  id: number;
  isbn: string;
  specialty: string;
  discipline: string;
  isBaseSet: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}
