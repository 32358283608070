import {
  AppAction,
  Application
} from '../apis/eols-app-link/eols-app-link.constants';
import { AppLinkRedirectErrorMessage } from '../pages/app-link-redirect/app-link-redirect.constants';
import { AssessmentTypeDto } from '../apis/eols-assessment-service/eols-assessment-service.dtos';

export enum AnalyticsAction {
  LOGIN_LAUNCH = 'LOGIN_LAUNCH',
  APP_LINK_LAUNCH = 'APP_LINK_LAUNCH',
  APP_LINK_ERROR = 'APP_LINK_ERROR',
  APP_LINK_OUT = 'APP_LINK_OUT',
  CREATE_A_QUIZ_CLICK = 'CREATE_A_QUIZ_CLICK',
  RESUME_QUIZ_CLICK = 'RESUME_QUIZ_CLICK',
  VIEW_RESULTS_CLICK = 'VIEW_RESULTS_CLICK',
  QUIZ_ME_ON_THIS_TOPIC_CLICK = 'QUIZ_ME_ON_THIS_TOPIC_CLICK',
  API_ERROR = 'API_ERROR',
  OSMOSIS_VIDEO_PLAYER_EVENT = 'OSMOSIS_VIDEO_PLAYER_EVENT',
  AI_CHAT_CITATION_CLICK = 'AI_CHAT_CITATION_CLICK',
  AI_CHAT_CITATION_LINK_CLICK = 'AI_CHAT_CITATION_LINK_CLICK',
  AI_CHAT_EDIT_CLICK = 'AI_CHAT_EDIT_CLICK',
  AI_CHAT_EDIT_CONFIRM_CLICK = 'AI_CHAT_EDIT_CONFIRM_CLICK',
  AI_CHAT_CHAT_CLICK = 'AI_CHAT_CHAT_CLICK',
  AI_CHAT_NEW_CHAT_CLICK = 'AI_CHAT_NEW_CHAT_CLICK',
  AI_CHAT_RETURN_CLICK = 'AI_CHAT_RETURN_CLICK',
  AI_CHAT_CANCEL_FEEDBACK_CLICK = 'AI_CHAT_CANCEL_FEEDBACK_CLICK',
  AI_CHAT_FEEDBACK_DIMENSION_CLICK = 'AI_CHAT_FEEDBACK_DIMENSION_CLICK',
  AI_CHAT_FEEDBACK_FACE_CLICK = 'AI_CHAT_FEEDBACK_FACE_CLICK',
  AI_CHAT_FEEDBACK_SUBMIT = 'AI_CHAT_FEEDBACK_SUBMIT',
  AI_CHAT_COPY_CLICK = 'AI_CHAT_COPY_CLICK',
  AI_CHAT_VIDEO_LINK_CLICK = 'AI_CHAT_VIDEO_LINK_CLICK',
  AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK = 'AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK',
  AI_CHAT_HOW_IT_WORKS_LINK_CLICK = 'AI_CHAT_HOW_IT_WORKS_LINK_CLICK',
  AI_CHAT_PROMPT_SUBMITTED = 'AI_CHAT_PROMPT_SUBMITTED',
  AI_CHAT_ERROR = 'AI_CHAT_ERROR',
  AI_CHAT_CITATION_EXPAND_TOGGLE = 'AI_CHAT_CITATION_EXPAND_TOGGLE',
  AI_CHAT_BOT_RESPONSE_COMPLETE = 'AI_CHAT_BOT_RESPONSE_COMPLETE',
  AI_CHAT_ACTION_WHILE_STREAMING_ERROR = 'AI_CHAT_ACTION_WHILE_STREAMING_ERROR',
  AI_CHAT_TOP_NAV_CLICK = 'AI_CHAT_TOP_NAV_CLICK',
  AI_CHAT_STUDENT_THEMES_CLICK = 'AI_CHAT_STUDENT_THEMES_CLICK',
  AI_CHAT_STUDENT_THEMES_RESULTS = 'AI_CHAT_STUDENT_THEMES_RESULTS',
  JS_ERROR = 'JS_ERROR',
}

export const IncludeAiChatPropsMap: Record<AnalyticsAction, boolean> = {
  [AnalyticsAction.JS_ERROR]: false,
  [AnalyticsAction.LOGIN_LAUNCH]: true,
  [AnalyticsAction.APP_LINK_LAUNCH]: true,
  [AnalyticsAction.APP_LINK_ERROR]: true,
  [AnalyticsAction.APP_LINK_OUT]: true,
  [AnalyticsAction.CREATE_A_QUIZ_CLICK]: false,
  [AnalyticsAction.RESUME_QUIZ_CLICK]: false,
  [AnalyticsAction.VIEW_RESULTS_CLICK]: false,
  [AnalyticsAction.QUIZ_ME_ON_THIS_TOPIC_CLICK]: false,
  [AnalyticsAction.API_ERROR]: true,
  [AnalyticsAction.OSMOSIS_VIDEO_PLAYER_EVENT]: true,
  [AnalyticsAction.AI_CHAT_CITATION_CLICK]: true,
  [AnalyticsAction.AI_CHAT_CITATION_LINK_CLICK]: true,
  [AnalyticsAction.AI_CHAT_EDIT_CLICK]: true,
  [AnalyticsAction.AI_CHAT_EDIT_CONFIRM_CLICK]: true,
  [AnalyticsAction.AI_CHAT_CHAT_CLICK]: true,
  [AnalyticsAction.AI_CHAT_NEW_CHAT_CLICK]: true,
  [AnalyticsAction.AI_CHAT_RETURN_CLICK]: true,
  [AnalyticsAction.AI_CHAT_CANCEL_FEEDBACK_CLICK]: true,
  [AnalyticsAction.AI_CHAT_FEEDBACK_DIMENSION_CLICK]: true,
  [AnalyticsAction.AI_CHAT_FEEDBACK_FACE_CLICK]: true,
  [AnalyticsAction.AI_CHAT_FEEDBACK_SUBMIT]: true,
  [AnalyticsAction.AI_CHAT_COPY_CLICK]: true,
  [AnalyticsAction.AI_CHAT_VIDEO_LINK_CLICK]: true,
  [AnalyticsAction.AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK]: true,
  [AnalyticsAction.AI_CHAT_HOW_IT_WORKS_LINK_CLICK]: true,
  [AnalyticsAction.AI_CHAT_PROMPT_SUBMITTED]: true,
  [AnalyticsAction.AI_CHAT_ERROR]: true,
  [AnalyticsAction.AI_CHAT_CITATION_EXPAND_TOGGLE]: true,
  [AnalyticsAction.AI_CHAT_BOT_RESPONSE_COMPLETE]: true,
  [AnalyticsAction.AI_CHAT_ACTION_WHILE_STREAMING_ERROR]: true,
  [AnalyticsAction.AI_CHAT_TOP_NAV_CLICK]: true,
  [AnalyticsAction.AI_CHAT_STUDENT_THEMES_CLICK]: true,
  [AnalyticsAction.AI_CHAT_STUDENT_THEMES_RESULTS]: true,
};

export enum AnalyticsRef {
  SIDEBAR = 'SIDEBAR',
  SYLLABUS = 'SYLLABUS',
  BATCH_ACTION_BAR = 'BATCH_ACTION_BAR',
}

export enum AnalyticsFilterType {
  EBOOK_FILTER = 'EBOOK_FILTER',
  STATUS_FILTER = 'STATUS_FILTER',
  TYPE_FILTER = 'TYPE_FILTER',
  GRADING_FILTER = 'GRADING_FILTER',
  HESI_FOCUS_CHAPTER = 'HESI_FOCUS_CHAPTER',
}

export type AnalyticsActionProps = {
  action: AnalyticsAction.LOGIN_LAUNCH;
  props: null;
} | {
  action: AnalyticsAction.API_ERROR;
  props: {
    status: string;
    action: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_ERROR;
  props: {
    type: AppLinkRedirectErrorMessage;
    status: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_OUT
    | AnalyticsAction.APP_LINK_LAUNCH;
  props: {
    linkType: AppAction;
    srcApp: Application;
    targetApp: Application;
  };
} | {
  action: AnalyticsAction.CREATE_A_QUIZ_CLICK;
  props: {};
} | {
  action: AnalyticsAction.OSMOSIS_VIDEO_PLAYER_EVENT;
  props: {
    type: string;
    activeChatId: string;
    videoId: string;
  };
} | {
  action: AnalyticsAction.QUIZ_ME_ON_THIS_TOPIC_CLICK;
  props: {
    assessmentTopicVtwId: string;
    selectedIsbn: string;
  };
} | {
  action: AnalyticsAction.RESUME_QUIZ_CLICK | AnalyticsAction.VIEW_RESULTS_CLICK;
  props: {
    assessmentId: number;
    assessmentType: AssessmentTypeDto;
    assessmentGoal: number;
    assessmentTopicVtwIds: string[];
  };
} | {
  action: AnalyticsAction.AI_CHAT_CITATION_CLICK;
  props: {
    type: string;
    resourceType: string;
    citationId: number;
    entryId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CITATION_LINK_CLICK;
  props: {
    type: string;
    resourceType: string;
    citationId: number;
    entryId: number;
    pageStart: number;
    eeoISBN: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_EDIT_CLICK;
  props: {
    targetChatId: number;
    targetChatTitle: string;
    isTargetActive: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CHAT_CLICK
    | AnalyticsAction.AI_CHAT_EDIT_CONFIRM_CLICK;
  props: {
    targetChatId: number;
    targetChatTitle: string;
    isTargetActive: boolean;
    isStreaming: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_RETURN_CLICK
    | AnalyticsAction.AI_CHAT_CANCEL_FEEDBACK_CLICK;
  props: null;
} | {
  action: AnalyticsAction.AI_CHAT_NEW_CHAT_CLICK;
  props: {
    isStreaming: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_FEEDBACK_FACE_CLICK;
  props: {
    rating: number;
    entryId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_FEEDBACK_SUBMIT;
  props: {
    rating: number;
    entryId: number;
    isFaceClick: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_FEEDBACK_DIMENSION_CLICK;
  props: {
    rating: number;
    name: string;
    isActiveWhenClicked: boolean;
    entryId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_COPY_CLICK | AnalyticsAction.AI_CHAT_PROMPT_SUBMITTED;
  props: {
    entryId: number;
    idx: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_VIDEO_LINK_CLICK;
  props: {
    entryId: number;
    recommendationId: number;
    videoId: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK
    | AnalyticsAction.AI_CHAT_HOW_IT_WORKS_LINK_CLICK;
  props: null;
} | {
  action: AnalyticsAction.AI_CHAT_ERROR;
  props: {
    type: string;
    entryId: number;
    chatId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CITATION_EXPAND_TOGGLE;
  props: {
    statusAfterToggle: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_BOT_RESPONSE_COMPLETE;
  props: {
    millisecondsToComplete: number;
    millisecondsToCompleteTotal: number;
    entryId: number;
    idx: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_ACTION_WHILE_STREAMING_ERROR;
  props: {
    actionRef: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_TOP_NAV_CLICK;
  props: {
    targetPage: string;
  };
} | {
  action: AnalyticsAction.JS_ERROR;
  props: {
    errorName: string;
    errorMessage: string;
    errorStack: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_STUDENT_THEMES_CLICK;
  props: {
    isRetry: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_STUDENT_THEMES_RESULTS;
  props: {
    requestHash: string;
    size: number;
  };
};
